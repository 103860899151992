import React, { Component } from 'react'
import Layout from '../components/layout'
import SubPage from '../components/subPage'
import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text } from '@rebass/emotion'
import { css } from '@emotion/react'
import SecondFold from './../components/home/SecondFold'


const c = css`
    font-weight: 400;
    line-height: 2em;
    hyphens: auto;
    width: 90%;
    max-width: 70rem;
    margin: 2.5rem auto 0 auto;

    em {
        font-style: normal;
        font-weight: 400;
        hyphens: manual;
        color: #4975ba;
        border-bottom: 1px solid #4975ba;
    }
`


const Content3 = css`
    hyphens: auto;
`

export default class Unternehmen extends Component {
    render() {
        return (
            <Layout title="Unternehmen">
                <SubPage>
                    <Flex justifyContent="center">
                        <Box width={1}>
                            <Text color="#6f7c82" fontSize={[18, 18, 18, 20]} fontWeight={600} textAlign="center">Über uns</Text>
                            <Heading color="#292e31" fontSize={[30, 42, 46, 60]} fontWeight={600} textAlign="center">Unternehmen</Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={40} textAlign="center">
                                Wir sind ein Unternehmen, das seit über 25 Jahren Stahlbalkone herstellt und montiert.
                                Die Balkonanlage das Produkt, das wir für Sie herstellen.
                            </Text>
                        </Box>
                    </Flex>
                    <Box mx={"auto"} css={{ maxWidth: 1250, width: '90%' }} mt={[25, 0]} mb={[50, 100]}>
                        <Flex my={[10, 20]} flexDirection="column">
                            <Box my={[20, 40]}>
                                <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Aus einem kleinen Fertigungsbetrieb, der 1996 von Dipl.-Ing. Arno Bregenzer gegründet wurde, hat sich ein erfolgreiches mittelständisches Familienunternehmen entwickelt:
                                    <b>Die Firma Balkonsysteme Taucha GmbH in Taucha bei Leipzig.</b>
                                </Text>
                                <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Durch vorausschauendes Denken, Investitionen in modernste Technologien und ein Team von hervorragend ausgebildeten und erfahrenen Mitarbeitern ist es Herrn Bregenzer gelungen, die Firma Balkonsysteme in kurzer Zeit zu einem angesehenen Unternehmen im Bereich Anbaubalkonsysteme aus Stahl zu entwickeln.
                                </Text>
                                <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Der Einsatz moderner CNC-Maschinen und der zügige Aufbau einer Konstruktions- und Planungsabteilung ermöglichten die Umsetzung immer komplexerer Anforderungen. Heute fertigt das Unternehmen Anbaubalkone mit höchstem Qualitätsstandard von der Planung über die Konstruktion und Fertigung bis hin zur Montage bundesweit nach Bauvorschrift und Kundenwunsch.
                                </Text>
                                <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Solides Wachstum und ausreichende Eigenkapitalausstattung sind wichtige Bestandteile unserer Unternehmensphilosophie. An unseren Standorten beschäftigen wir derzeit über 20 Mitarbeiterinnen und Mitarbeiter. Personelle Kontinuität, Zuverlässigkeit und Qualität sind die Basis, auf die sich unsere Kunden verlassen können.
                                </Text>
                                {/* <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Mit der Übergabe der Balkonsysteme Taucha GmbH 2015 an den Sohn, Herrn Jan Bregenzer, wurde die Zukunft des Unternehmens durch die nächste Generation sichergestellt.
                                </Text>
                                */}
                            </Box>

                        </Flex>

                    </Box>

                    <SecondFold />
                </SubPage>
            </Layout>
        )
    }
}
